import React, { useEffect } from "react";
import Col1 from "./Components/Col1";
import Col2 from "./Components/Col2";
import Col3 from "./Components/Col3";
import Col4 from "./Components/Col4";
import AOS from "aos";
import "aos/dist/aos.css";

const IntroDabosa = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <Col1 aos="fade-up" />
      <Col2 aos="fade-up" />
      <Col3 aos="fade-up" />
      <Col4 aos="fade-up" />
    </div>
  );
};

export default IntroDabosa;
