import React, { useEffect, useState } from "react";
import Advise from "./Components/Advise";
import AOS from "aos";
import "aos/dist/aos.css";
import Reason from "./Components/Reason";
import Event from "./Components/Event";
import Reviews from "./Components/Reviews";
import { baseApi } from "src/constant";

const HomePage = () => {
  const [homePage, setHomePage] = useState()
  const [camNhan, setCamNhan] = useState()
  const [news, setNews] = useState()

  useEffect(() => {
    AOS.init({ duration: 1000 });

    fetch(`${baseApi}/pages/?type=HOME`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setHomePage(res[0])
    })

    fetch(`${baseApi}/blogs/?category__name=Cảm%20nhận%20của%20khách%20hàng`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setCamNhan(res)
    })

    fetch(`${baseApi}/blogs/?category__name=Tin+tức+%26+Sự+kiện`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setNews(res)
    })
  }, []);

  return (
    <div>
      <Advise aos="fade-up" data={homePage}/>
      <Reason aos="fade-up" />
      <Event aos="fade-up" data={news}/>
      <Reviews aos="fade-up" data={camNhan}/>
    </div>
  );
};

export default HomePage;
