import React from "react";
import Slider from "react-slick";
import ReviewImages from "src/assets/HomePage/Reviews";
import { Review } from "src/constant/Array";

const Reviews = ({ aos, data }: any) => {
  const slider = React.useRef<any>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 500,
        },
      },
    ],
  };

  if(data)
    return (
      <div
        data-aos={aos}
        className="pb-5"
        style={{
          backgroundImage: `url(${ReviewImages.bg_review})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div className="container-xxl">
          <div
            className="d-flex justify-content-center flex-column"
            style={{
              width: "max-content",
            }}
          >
            <div className="title-body-2">Cảm nhận của khách hàng</div>
            <div
              style={{
                height: 3,
                backgroundColor: "#00b09b",
              }}
            />
          </div>
          <div className="w-100 pt-5 slider-container">
            <Slider {...settings}>
              {data?.map((item: any) => {
                return (
                  <div key={item.id}>
                    <div className="item-review">
                      <div>
                        <div className="d-flex align-items-center gap-2">
                          <img src={item.thumbnail} width={56} height={56} alt="" style={{borderRadius: '50%'}} />
                          <div>
                            <div className="fw-semibold">{item.title}</div>
                            <div
                              style={{
                                fontSize: 13,
                              }}
                            >
                              {item.short_description}
                            </div>
                          </div>
                        </div>
                        <div className="pt-2" dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={ReviewImages.tym}
                          className="btn border border-0 p-0"
                          width={24}
                          height={24}
                          alt=""
                        />
                        <img
                          src={ReviewImages.comment}
                          className="btn border border-0 p-0"
                          width={24}
                          height={24}
                          alt=""
                        />
                        <img
                          src={ReviewImages.tele}
                          className="btn border border-0 p-0"
                          width={24}
                          height={24}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    );
};

export default Reviews;
