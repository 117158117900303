import Cookies from 'js-cookie'

export const current_language = 'current_language'

export const SaveCookies = (name: string, value: string) => {
    try {
        Cookies.set(name, value)
    } catch (error) {
        console.log(error);
    }
}

export const GetCookies = (name: string) => {
    try {
        const result = Cookies.get(name)
        return result
    } catch (error) {
        throw error
    }
}

export const RemoveCookies = (name: string) => {
    Cookies.remove(name)
}