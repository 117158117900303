import { UnorderedListOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import HeaderImages from "../../../assets/Header";
import { useNavigate } from "react-router-dom";

const ItemRightForMobile = () => {
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div className="btn border border-0 p-0">GIỚI THIỆU</div>,
      children: [
        {
          key: "1-1",
          label: (
            <button
              onClick={() => navigate("/introducing-dabosa-group")}
              className="btn border border-0 p-0"
            >
              Về Dabosa Group
            </button>
          ),
        },
        {
          key: "1-2",
          label: (
            <button
              onClick={() => navigate("/lanh-dao")}
              className="btn border border-0 p-0"
            >
              Ban Lãnh Đạo
            </button>
          ),
        },
      ],
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => navigate("/course")}
          className="btn border border-0 p-0"
        >
          KHOÁ HỌC
        </div>
      ),
    },
    {
      key: "7",
      label: <div className="btn border border-0 p-0">DU HỌC</div>,
      children: [
        {
          key: "7-1",
          label: (
            <div
              onClick={() => navigate("/du-hoc", { state:  { id: 'du-hoc', type: 'DU_HOC' } })}
              className="btn border border-0 p-0"
            >
              Du học Nhật Bản
            </div>
          ),
        },
        {
          key: "7-2",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-hoc", { state: { id: 'du-hoc', type: 'DU_HOC' } })}
            >
              Du học Hàn Quốc
            </div>
          ),
        },
        {
          key: "7-3",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-hoc", { state: { id: 'du-hoc', type: 'DU_HOC' } })}
            >
              Du học Đức
            </div>
          ),
        },
        {
          key: "7-4",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-hoc", { state: { id: 'du-hoc', type: 'DU_HOC' } })}
            >
              Du học Úc
            </div>
          ),
        },
        {
          key: "7-5",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-hoc", { state: { id: 'du-hoc', type: 'DU_HOC' } })}
            >
              Du học Trung Quốc
            </div>
          ),
        },
        {
          key: "7-6",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-hoc", { state: { id: 'du-hoc', type: 'DU_HOC' } })}
            >
              Du học Đài Loan
            </div>
          ),
        },
        {
          key: "7-7",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-hoc", { state: { id: 'cam-nang-du-hoc', type: 'CAM_NANG_DU_HOC' } })}
            >
              Cẩm nang du học
            </div>
          ),
        },
        {
          key: "7-8",
          label: (
            <div
              onClick={() => navigate("/du-hoc", { state: { id: 'du-hoc-tieu-bieu', type: 'DU_HOC_TIEU_BIEU' } })}
              className="btn border border-0 p-0"
            >
              Các hoạt động tiêu biểu
            </div>
          ),
        },
      ],
    },
    {
      key: "3",
      label: <div className="btn border border-0 p-0">DU LỊCH</div>,
      children: [
        {
          key: "3-1",
          label: (
            <div
              onClick={() => navigate("/du-lich", { state:  { id: 'du-lich-noi-dia', type: 'DU_LICH_NOI_DIA' } })}
              className="btn border border-0 p-0"
            >
              Du lịch nội địa
            </div>
          ),
        },
        {
          key: "3-2",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-lich", { state: { id: 'du-lich-quoc-te', type: 'DU_LICH_QUOC_TE' } })}
            >
              Du lịch quốc tế
            </div>
          ),
        },
        {
          key: "3-3",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-lich", { state: { id: 'cam-nang-du-lich', type: 'CAM_NANG_DU_LICH' } })}
            >
              Cẩm nang du lịch
            </div>
          ),
        },
        {
          key: "3-4",
          label: (
            <div
              onClick={() => navigate("/du-lich", { state: { id: 'du-lich-tieu-bieu', type: 'DU_LICH_TIEU_BIEU' } })}
              className="btn border border-0 p-0"
            >
              Các hoạt động tiêu biểu
            </div>
          ),
        },
      ],
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => navigate("/news")}
          className="btn border border-0 p-0"
        >
          TIN TỨC
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => navigate("/contact")}
          className="btn border border-0 p-0"
        >
          LIÊN HỆ
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() => navigate("/recruitment")}
          className="btn border border-0 p-0"
        >
          TUYỂN DỤNG
        </div>
      ),
    },
    // {
    //   key: "8",
    //   label: (
    //     <button className="btn border border-0 p-0 d-flex align-items-center gap-2">
    //       EN <img src={HeaderImages.my} width={24} />
    //     </button>
    //   ),
    // },
  ];

  return (
    <div className="d-block d-lg-none">
      <Dropdown menu={{ items }} placement="bottomRight">
        <button className="btn border border-0 p-0">
          <UnorderedListOutlined style={{ fontSize: 20, color: "#589a83" }} />
        </button>
      </Dropdown>
    </div>
  );
};

export default ItemRightForMobile;
