import { CaretRightOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EventImages from "src/assets/Home/Event";
import TypicalImages from "src/assets/Home/Typical";
import HomePageImages from "src/assets/HomePage";

const Typical = ({ aos, data }: any) => {
  const navigate = useNavigate()

  const location = useLocation();
  const state = location.state; // Lấy state được truyền
  const id = state?.id;
  const type = state?.type

  useEffect(() => {
    if (id && type == 'DU_LICH_TIEU_BIEU') {
      const element = document.querySelector(`#${id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);

  return (
    <div
      id="du-lich-tieu-bieu"
      data-aos={aos}
      className="pb-5"
      style={{
        backgroundColor: "#fff9ef",
      }}
    >
      <div className="container-xxl">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-2">Các hoạt động tiêu biểu</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#00b09b",
            }}
          />
        </div>

        <div className="row mt-4">
          <div className="col-md-12 col-lg-6">
            <div
              className="event-image-1"
              style={{
                backgroundImage: `url(${data[0].thumbnail})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    {data[0].title}
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />
                    <div className="fw-bolder" onClick={() => navigate(`/blog/${data[0].id}`)}>Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-lg-6">
            <div className="d-block d-md-none mt-2" />
            <div
              className="event-image-2"
              style={{
                backgroundImage: `url(${data[1].thumbnail})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    {data[1].title}
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder" onClick={() => navigate(`/blog/${data[1].id}`)}>Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div
                className="event-image-3"
                style={{
                  backgroundImage: `url(${data[2].thumbnail})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: 280,
                }}
              >
                <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                  <div
                    style={{
                      backgroundColor: `rgba(0,0,0,0.3)`,
                      borderRadius: 8,
                      padding: 8,
                    }}
                  >
                    <div className="txt-title-event">
                      {data[2].title}
                    </div>
                    <button className="btn-see-more-event">
                      <img
                        src={HomePageImages.arrow_right}
                        width={18}
                        height={12}
                      />
                      <div className="fw-bolder" onClick={() => navigate(`/blog/${data[2].id}`)}>Đọc tiếp</div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="event-image-4"
                style={{
                  backgroundImage: `url(${data[3].thumbnail})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: 280,
                }}
              >
                <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                  <div
                    style={{
                      backgroundColor: `rgba(0,0,0,0.3)`,
                      borderRadius: 8,
                      padding: 8,
                    }}
                  >
                    <div className="txt-title-event">
                     {data[3].title}
                    </div>
                    <button className="btn-see-more-event">
                      <img
                        src={HomePageImages.arrow_right}
                        width={18}
                        height={12}
                      />
                      <div className="fw-bolder" onClick={() => navigate(`/blog/${data[3].id}`)}>Đọc tiếp</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 mt-2">
          <div className="col-lg-3">
            <div
              className="event-image-5"
              style={{
                backgroundImage: `url(${data[4].thumbnail})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                     {data[4].title}
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder" onClick={() => navigate(`/blog/${data[4].id}`)}>Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 event-image-6">
            <div
              className="event-image-6"
              style={{
                backgroundImage: `url(${data[5].thumbnail})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    {data[5].title}
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder" onClick={() => navigate(`/blog/${data[5].id}`)}>Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 event-image-7">
            <div
              className="event-image-7"
              style={{
                backgroundImage: `url(${data[6].thumbnail})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                     {data[6].title}
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />
                    <div className="fw-bolder" onClick={() => navigate(`/blog/${data[6].id}`)}>Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <button className="btn-see-all-tour-inland">Xem tất cả</button>
      </div>
    </div>
  );
};

export default Typical;
