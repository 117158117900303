import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import HeadTab from "src/components/HeadTab/HeadTab";
import { baseApi } from "src/constant";

const parseContent = (htmlContent: any) => {
  // Thay đổi đường dẫn tương đối thành đường dẫn tuyệt đối
  return htmlContent.replace(/src="\/media\//g, `src="${baseApi}/media/`);
};

const DetailBlog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState<any>()

  useEffect(() => {
    fetch(`${baseApi}/blogs/${id}/`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setBlog(res)
    })
  }, [])

  if(blog)
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div dangerouslySetInnerHTML={{ __html: parseContent(blog?.content) }}></div>
      </div>
    </div>
  );
};

export default DetailBlog;
