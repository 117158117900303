import React from "react";
import IntroImages from "src/assets/Intro";

const Col3 = ({ aos }: any) => {
  return (
    <div
      data-aos={aos}
      className="pt-5"
      style={{
        background: "linear-gradient(90deg,#007261, #21c586)",
        height: "max-content",
      }}
    >
      <div className="container-xxl pb-5">
        <div className="row col-md-12 justify-content-md-center gap-2">
          <div className="col-md-5">
            <div className='d-flex align-items-center justify-content-center'>
            <div
              className="p-5 intro-img-min-5"
              style={{
                backgroundImage: `url(${IntroImages.min_5})`,
              }}
            >
              <div
                className="d-flex justify-content-center flex-column pt-5"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">Tầm nhìn</div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <div className="pt-3">
                DABOSA định hướng phát triển trở thành một trong những đơn vị uy
                tín hàng đầu tại Việt Nam trong lĩnh vực du học toàn cầu và du
                lịch lữ hành, là “người bạn tâm giao” đồng hành cùng quý khách
                hàng mọi lúc, mọi nơi.
              </div>
            </div>
              </div>
          </div>
          <div className="col-md-5">
          <div className='d-flex align-items-center justify-content-center'>
          <div
              className="p-5 intro-img-min-5"
              style={{
                backgroundImage: `url(${IntroImages.min_6})`,
              
              }}
            >
              <div
                className="d-flex justify-content-center flex-column pt-5"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">Sứ mệnh</div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <div className="pt-3">
                DABOSA không ngừng cố gắng trở thành cầu nối tin cậy, thiện chí
                chân thành với đối tác, khách hàng, mang đến những giải pháp tối
                ưu với chất lượng tốt, chi phí hợp lý, góp phần chắp cánh ước mơ
                cho thế hệ trẻ Việt Nam có cơ hội phát triển sự nghiệp bền vững
                và những chuyến đi thư giãn, trải nghiệm thật tuyệt vời khi quý
                khách hàng lựa chọn chúng tôi là người bạn đồng hành.
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          background: "#fff8ed",
        }}
      >
        <div className="row col-md-12">
          <div className="col-md-6 pt-5 ">
            <div className="px-5 ">
              <div
                className="d-flex justify-content-center flex-column pt-5 pb-2"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success ">
                  Giá trị cốt lõi
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">TÂM:</div>
              <div className="fs-5">
                Làm việc và cống hiến, phục vụ khách hàng bằng tất cả tâm huyết,
                trái tim nhiệt thành của mình, lấy khách hàng làm trung tâm.
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">TẦM:</div>
              <div className="fs-5">
                Không ngừng cố gắng nâng tầm định vị doanh nghiệp, chất lượng
                sản phẩm, dịch vụ trên thị trường
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">TÍN:</div>
              <div className="fs-5">
                Coi trọng uy tín, luôn không ngừng nỗ lực để nâng cao uy tín
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">TRÍ:</div>
              <div className="fs-5">
                Trau dồi, học hỏi với tinh thần cầu thị để nâng cao kiến thức,
                sự hiểu biết sâu rộng và trí tuệ.
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">ĐỨC:</div>
              <div className="fs-5">
                Tu dưỡng, rèn luyện đạo đức, giữ gìn phẩm giá, nhân cách của bản
                thân.
              </div>
            </div>
          </div>
          <div className="d-block d-sm-none mt-3" />
          <div className="col-md-6">
            <img src={IntroImages.min_14} className="img-min-7" />
          </div>
        </div>
        <div className="d-block d-sm-none mt-3" />
        <div className="col-md-12 row">
          <div className="col-md-6">
            <img src={IntroImages.min_8} className="img-min-8" alt="" />
          </div>
          <div className="col-md-6">
            <div className="px-5">
              <div
                className="d-flex justify-content-center flex-column pt-5 pb-2"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">
                  Triết lý hoạt động:
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <br />
              <div className="fs-5">• Lấy chữ Tâm làm kim chỉ nam.</div>
              <div className="fs-5">
                • Luôn cầu thị, không ngừng đổi mới, sáng tạo.
              </div>
              <div className="fs-5">
                • Là nơi học tập, làm việc lý tưởng, là người bạn tâm giao.
              </div>
            </div>
          </div>
        </div>

        <div className="row col-md-12">
          <div className="col-md-6">
            <div className="px-5">
              <div
                className="d-flex justify-content-center flex-column pt-5 pb-2"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">
                  Văn hóa doanh nghiệp:
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <br />
              <div className='fs-5' >
                • Chỉ bảo, chiếu cố, giúp đỡ lẫn nhau cùng phát triển; chân
                thành, trung thực
              </div>
              <div className='fs-5'>• Tinh thần khuyến khích, xây dựng giúp nhau tốt lên.</div>
              <div className='fs-5'>
                • Tôn trọng và lắng nghe ý kiến của nhau, trách nhiệm cao với
                công việc.
              </div>
              <div className='fs-5'>
                • Kỷ luật, công bằng, bình đẳng trong ứng xử và lợi ích hợp pháp
                xứng đáng của nhau.
              </div>
              <div className='fs-5'>• Không quản ngại trước khó khăn, thử thách.</div>
              <div className='fs-5'>• Minh bạch, rõ ràng trong công việc.</div>
            </div>
          </div>
          <div className="d-block d-sm-none mt-3" />
          <div className="col-md-6">
            <img src={IntroImages.min_9} className='intro-img-min-9' alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Col3;
