const HomePageImages = {
    bg_introduce: require('./bg_introduce.png'),
    dantri: require('./dantri.png'),
    vn: require('./vn.png'),
    vnnet: require('./vnnet.png'),
    vtv: require('./vtv.png'),
    bg_reason: require('./bg_reason.png'),
    img_6_reason: require('./img_6_reason.png'),
    ic_star: require('./ic_star.png'),
    right_1: require('./right_1.png'),
    right_2: require('./right_2.png'),
    right_3: require('./right_3.png'),
    right_4: require('./right_4.png'),
    right_5: require('./right_5.png'),
    right_6: require('./right_6.png'),
    bg_reason1: require('./bg_reason1.png'),
    arrow_right: require('./arrow_right.png'),
    bacninh_online:require('./bacninh.jpg'),
}

export default HomePageImages