import React from "react";
import IntroImages from "src/assets/Intro";
import "../intro.css";

const Col4 = ({ aos }: any) => {
  return (
    <div
      data-aos={aos}
      style={{
        backgroundImage: `url(${IntroImages.min_13})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "max-content",
      }}
    >
      <div className="container-xxl py-5">
        <div className="d-flex justify-content-center">
          <div
            className="d-flex justify-content-center flex-column pt-5 pb-2"
            style={{
              width: "max-content",
            }}
          >
            <div className="fs-2 fw-bolder text-success">
              Ý nghĩa tên thương hiệu :
            </div>
            <div
              style={{
                height: 3,
                backgroundColor: "#007261",
              }}
            />
          </div>
        </div>
        <div className="row col-md-12 gap-3 justify-content-md-center pt-4">
         <div className="col-md-3 container-img-intro">
         <img src={IntroImages.min_10} className="img-min-10" />
         </div>
         <div className="col-md-3 container-img-intro">
         <img src={IntroImages.min_11} className="img-min-10" />
         </div>
         <div className="col-md-3 container-img-intro">
         <img src={IntroImages.min_12} className="img-min-10" />
         </div>
        
        </div>

        <div className="py-5">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <img
              src={IntroImages.dabosa}
              className="img-logo-col-4"
            />
          
          </div>

          <div className="d-flex align-items-center justify-content-center">
            <div
              className="p-4 rounded-4 mt-5 d-flex align-items-center flex-column container-y-nghia"
              style={{
                background: "linear-gradient(90deg,#007261, #21c586)",
                height: "max-content",
              }}
            >
              <div
                className="d-flex justify-content-center flex-column pb-2"
                style={{
                  width: "max-content",
                }}
              >
                <div
                  className="fs-2 fw-bolder"
                  style={{
                    color: "#ffde59",
                  }}
                >
                  Ý nghĩa câu Slogan:
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#ffde59",
                  }}
                />
              </div>

              <div className="text-white pt-2 text-center">
                “We are specialists” – Chúng tôi là chuyên gia, thể hiện sự
                quyết tâm cũng như lời khẳng định về chất lượng sản phẩm, dịch
                vụ, sự chuyên nghiệp của con người DABOSA.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Col4;
