import React, { useEffect, useState } from "react";
import ContactImages from "src/assets/Contact";
import FooterImages from "src/assets/Footer";
import HeadTab from "src/components/HeadTab/HeadTab";
import { baseApi } from "src/constant";

const Contact = () => {
  const [webInfo, setWebInfo] = useState<any>()

  useEffect(() => {
    fetch(`${baseApi}/web-info/`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setWebInfo(res[0])
    })
  }, [])
  
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">Thông tin liên hệ</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>

        <div className="row col-md-12 pt-5">
          <div className="col-md-3">
            <div>
                  {
                    webInfo && webInfo?.web_info_hotlines?.map((item: any) => {
                      return (
                              <>
                                <div className="text-success fw-semibold">Hotline {item.type == 'DU_HOC' ? 'Du Học' : 'Du Lịch'}:</div>
                                <div className="text-success">{item?.phone}</div>
                              </>
                      )
                    })
                  }
            </div>
          </div>
          <div className="d-block d-sm-none my-2" />
          <div className="col-md-3">
            <div>
              <div className="text-success fw-bolder">Email:</div>
              <div className="text-success fw-bolder">
                {webInfo?.email}
              </div>
              <div className="text-success fw-bolder">Website:</div>
              <div className="text-success fw-bolder">{webInfo?.website}</div>
            </div>
          </div>
          <div className="d-block d-sm-none mt-2" />
          <div className="col-md-6">
            <div className="text-success fw-semibold fs-5">
              Liên kết mạng xã hội:
            </div>

            <div>
              <div className="d-flex align-items-center gap-3 pt-4">
                <div className="gap-2 d-flex align-items-center flex-column">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(
                          `${webInfo?.web_info_links[0]?.url}`,
                          "_blank"
                        )
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[0]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[0]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(
                          `${webInfo?.web_info_links[1]?.url}`,
                          "_blank"
                        )
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[1]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[1]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA TRAVEL
                    </div>
                  </button>
                </div>
                <div className="gap-2 d-flex align-items-center flex-column">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`${webInfo?.web_info_links[2]?.url}`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[2]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[2]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`${webInfo?.web_info_links[3]?.url}`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[3]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[3]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      onClick={() =>
                        window
                          .open(
                            `${webInfo?.web_info_links[3]?.url}`,
                            "_blank"
                          )
                          ?.focus()
                      }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row col-md-12 pt-5 align-items-md-center">
          <div className="col-md-5">
            <div className="fw-bolder fs-3 text-success">Trụ sở chính:</div>
            <div className="fw-semibold fs-4 text-success">
              {webInfo?.name}
            </div>
            <div className="text-success pt-1">
              {webInfo?.address}
            </div>
          </div>
          <div className="d-block d-sm-none my-2" />
          <div className="col-md-7">
            <img src={ContactImages.contact_map} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
