import React, { useEffect, useState } from "react";
import Programs from "./Components/Programs";
import Handbook from "./Components/Handbook";
import Event from "./Components/Event";
import Typical from "./Components/Typical";
import StudyAbroad from "./Components/StudyAbroad";
import AOS from "aos";
import "aos/dist/aos.css";
import { baseApi } from "src/constant";

const Study_Abroad = () => {
  const [duhoc, setDuHoc] = useState()
  const [programs, setPrograms] = useState()
  const [camNang, setCamNang] = useState()
  const [tieuBieu, setTieuBieu] = useState()
  useEffect(() => {
    AOS.init({ duration: 1000 });

    
    fetch(`${baseApi}/pages/?type=DU_HOC`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setDuHoc(res[0])
    })

    fetch(`${baseApi}/blogs/?category__name=Du học&search=Du`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setPrograms(res)
    })

    fetch(`${baseApi}/blogs/?category__name=Du%20học&search=Cẩm`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setCamNang(res)
    })

    fetch(`${baseApi}/blogs/?category__name=Du%20học&search=Các`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setTieuBieu(res)
    })
  }, []);

  if(duhoc && programs && camNang && tieuBieu)
  return (
    <div>
      <StudyAbroad aos="fade-up" data={duhoc}/>
      <Programs aos="fade-up" data={duhoc} programs={programs}/>
      <Handbook aos="fade-up" data={camNang}/>
      {/* <Event aos="fade-up" /> */}
      <Typical aos="fade-up" data={tieuBieu}/>
    </div>
  );
};

export default Study_Abroad;
