import {
  CheckCircleFilled,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import bg from "src/assets/Home/duhoc.png";
import "../study.css";
import HeadTab from "src/components/HeadTab/HeadTab";
import TypicalImages from "src/assets/Home/Typical";
import Slider from "react-slick";

const StudyAbroad = ({ aos, data }: any) => {
  const navigate = useNavigate();
  const slider = React.useRef<any>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
  };

  return (
    <div data-aos={aos}>
      <HeadTab />
      <div>
        <Slider ref={slider} {...settings}>
          {
            data?.page_thumbnails?.map((item: any, idx: any) => {
              return (
                <div key={item.id} className="container-advise">
                  <div
                    className="container-advise"
                    style={{
                      backgroundImage: `url(${item?.thumbnail})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="w-100 h-100 px-5">
                      <div className="d-flex align-items-center justify-content-between w-100 advise-slick">
                        <button
                          className="btn border border-0 p-0"
                          onClick={() => slider?.current?.slickPrev()}
                        >
                          <LeftOutlined className="ic-arrow-advise" />
                        </button>
                        <button
                          className="btn border border-0 p-0"
                          onClick={() => slider?.current?.slickNext()}
                        >
                          <RightOutlined className="ic-arrow-advise" />
                        </button>
                      </div>
                      <div className="d-flex justify-content-center gap-3">
                        <div className="d-flex align-items-center justify-content-center flex-column btn-bottom-advise">
                          <button
                            onClick={() => window.scrollTo(0, 4400)}
                            className="btn-tuvan"
                          >
                            Tư vấn Du học
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Slider>
      </div>
    </div>
  );
};

export default StudyAbroad;
