import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const DropdownDuLich = () => {
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => navigate("/du-lich", { state: { id: 'du-lich-noi-dia', type: 'DU_LICH_NOI_DIA' } })}
          className="btn border border-0 p-0"
        >
          Du lịch nội địa
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="btn border border-0 p-0"
          onClick={() => navigate("/du-lich", { state: { id: 'du-lich-quoc-te', type: 'DU_LICH_QUOC_TE' } })}
        >
          Du lịch quốc tế
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className="btn border border-0 p-0"
          onClick={() => navigate("/du-lich", { state: { id: 'cam-nang-du-lich', type: 'CAM_NANG_DU_LICH' } })}
        >
          Cẩm nang du lịch
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => navigate("/du-lich", { state: { id: 'du-lich-tieu-bieu', type: 'DU_LICH_TIEU_BIEU' } })}
          className="btn border border-0 p-0"
        >
          Các hoạt động tiêu biểu
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <button className="btn-right fw-medium gap-1 d-flex align-items-center">
        DU LỊCH
        <DownOutlined style={{ fontSize: 10 }} />
      </button>
    </Dropdown>
  );
};

export default DropdownDuLich;
