import React, { useEffect, useState } from "react";
import CourseImages from "src/assets/Course";
import HeadTab from "src/components/HeadTab/HeadTab";
import { baseApi } from "src/constant";

const Course = () => {
  const [course, setCourse] = useState<any>()
  useEffect(() => {
    fetch(`${baseApi}/blogs/?category__name=Khoá học`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setCourse(res)
    })
  }, [])
  if(course)
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
            Các khoá học ngoại ngữ tại DABOSA
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 my-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={course[0]?.thumbnail}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
              {course[0]?.title}
            </div>
            <div className="fw-semibold">{course[0]?.short_description}</div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: course[0]?.content }}></div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              Đăng ký ngay
            </button>
          </div>
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 mb-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
            {course[1]?.title}
            </div>
            <div className="fw-semibold">{course[1]?.short_description}</div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: course[1]?.content }}></div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              Đăng ký ngay
            </button>
          </div>
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={course[1].thumbnail}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 my-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={course[2].thumbnail}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
              {course[2].title}
            </div>
            <div className="fw-semibold">{course[2].short_description}</div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: course[2]?.content }}></div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              Đăng ký ngay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
