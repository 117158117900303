import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import HeaderImages from "src/assets/Header";
import TourInland from "./Components/TourInland";
import TourInternational from "./Components/TourInternational";
import Travel_Guide from "./Components/TravelGuide";
import Typical from "./Components/Typical";
import Travel from "./Components/Travel";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import { baseApi } from "src/constant";

function Home() {
  const myRef = useRef<any>(null)
const {state} = useLocation()
const id = state?.id || ""
  const [dulich, setDulich] =useState()
  const [noiDia, setNoiDia] =useState()
  const [quocte, setQuocte] =useState()
  const [camNang, setCamNang] =useState()
  const [tieuBieu, setTieuBieu] =useState()

  useEffect(() => {
    AOS.init({ duration: 1000 });
    switch (id) {
      case 7:
        window.scrollTo(0, 900);
        break;
      case 8:
        window.scrollTo(0, 2200);
        break;
      case 9:
        window.scrollTo(0, 3220);
        break;
      default:
        break;
    }

    fetch(`${baseApi}/pages/?type=DU_LICH`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setDulich(res[0])
    })

    fetch(`${baseApi}/blogs/?sub_category=11`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setNoiDia(res)
    })

    fetch(`${baseApi}/blogs/?sub_category=12`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setQuocte(res)
    })

    fetch(`${baseApi}/blogs/?sub_category=13`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setCamNang(res)
    })

    fetch(`${baseApi}/blogs/?sub_category=14`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setTieuBieu(res)
    })
    
  }, [id]);

  if(dulich && noiDia && quocte && camNang && tieuBieu)
  return (
    <div>
      <Travel aos="fade-up" data={dulich}/>
      <TourInland aos="fade-up" data={noiDia}/>
      <TourInternational aos="fade-up" data={quocte}/>
      <Travel_Guide aos="fade-up" data={camNang}/>
      <Typical aos="fade-up" data={tieuBieu}/>
    </div>
  );
}

export default Home;
