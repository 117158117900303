import React, { useEffect, useState } from "react";
import HeaderImages from "../../assets/Header";
import "./footer.css";
import { Input } from "antd";
import Footer_Button from "../../constant/Array";
import {
  CaretUpFilled,
  ChromeOutlined,
  MailOutlined,
  PhoneOutlined,
  UpOutlined,
} from "@ant-design/icons";
import FooterImages from "src/assets/Footer";
import { useNavigate } from "react-router-dom";
import { baseApi } from "src/constant";

const Footer = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");

  const [webInfo, setWebInfo] = useState<any>()

  useEffect(() => {
    fetch(`${baseApi}/web-info/`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setWebInfo(res[0])
    })
  }, [])

  const handleUpload = () => {};

  const handleNavigate = (i: number) => {
    switch (i) {
      case 7:
        navigate("/du-lich", { state: { id: 7 } });
        break;
      case 8:
        navigate("/du-lich", { state: { id: 8 } });
        break;
      case 9:
        navigate("/du-lich", { state: { id: 9 } });
        break;

      default:
        break;
    }
  };

  const handleRegister = async () => {
    await fetch(`${baseApi}/user-registers/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: fullName,
        email: email,
        phone: phone,
        service: service
      }), 
    });

    setEmail('')
    setFullName('')
    setPhone('')
    setService('')
  }

  if(webInfo)
  return (
    <div className="">
      <div
        className="row col-md-12 mb-5"
        style={{
          background: "#fdf7e1",
        }}
      >
        <img src={FooterImages.dang_ky_tu_van} className="col-md-6" alt="" />
        <div className="col-md-6 pt-4 px-4">
          <h3 className="text-success fw-bolder">Đăng ký tư vấn</h3>
          <div>
            <div className="fw-semibold pb-2">Họ và tên:</div>
            <Input
              value={fullName}
              onChange={(text) => setFullName(text.target.value)}
              placeholder="..."
              className="input-form-register"
            />
          </div>
          <div className="pt-2">
            <div className="fw-semibold pb-2">Email:</div>
            <Input
              value={email}
              onChange={(text) => setEmail(text.target.value)}
              placeholder="..."
              className="input-form-register"
            />
          </div>
          <div className="pt-2">
            <div className="fw-semibold pb-2">Số điện thoại:</div>
            <Input
              value={phone}
              onChange={(text) => setPhone(text.target.value)}
              placeholder="..."
              className="input-form-register"
            />
          </div>
          <div className="pt-2">
            <div className="fw-semibold pb-2">Dịch vụ cần tư vấn:</div>
            <Input value={service} placeholder="..." className="input-form-register" onChange={(text) => setService(text.target.value)}/>
          </div>
          <button className="btn border border-0 p-0 mb-5 mt-3 text-white bg-success rounded-5 px-5 py-2 fw-semibold" onClick={handleRegister}>
            Đăng ký
          </button>
        </div>
      </div>
      <div className="container-xxl">
        <div className="row col-md-12">
          <div className="col-md-4">
            <img
              src={require("src/assets/Logo/logo_app.jpg")}
              height={45}
              className="img-logo-footer"
            />
          </div>
          <div className="col-md-8">
            <div className="d-block d-sm-none mt-5" />
            <div className="text-success fw-bolder fs-3">
              Liên kết mạng xã hội:
            </div>

            <div>
              <div className="d-flex align-items-center justify-content-center gap-3 pt-4">
                <button
                  className="btn-fb-link"
                  onClick={() =>
                    window
                      .open(
                        `${webInfo?.web_info_links[0]?.url}`,
                        "_blank"
                      )
                      ?.focus()
                  }
                >
                  <img
                    src={webInfo?.web_info_links[0]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[0]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <div className="fw-semibold" style={{ fontSize: 14 }}>
                    DABOSA GROUP
                  </div>
                </button>
                <button
                  className="btn-fb-link"
                  onClick={() =>
                    window
                      .open(`${webInfo?.web_info_links[1]?.url}`, "_blank")
                      ?.focus()
                  }
                >
                  <img
                    src={webInfo?.web_info_links[1]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[1]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <div className="fw-semibold" style={{ fontSize: 14 }}>
                    DABOSA TRAVEL
                  </div>
                </button>
                <div className="d-none d-md-block">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`${webInfo?.web_info_links[2]?.url}`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[2]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[2]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
                <div className="d-none d-md-block">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`${webInfo?.web_info_links[3]?.url}`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[3]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[3]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      // onClick={() =>
                      //   window
                      //     .open(
                      //       `https://www.tiktok.com/@dabosa.group`,
                      //       "_blank"
                      //     )
                      //     ?.focus()
                      // }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-block d-md-none">
                <div className="d-flex justify-content-center align-items-center gap-3 pt-3">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`${webInfo?.web_info_links[2]?.url}`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[2]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[2]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`${webInfo?.web_info_links[3]?.url}`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={webInfo?.web_info_links[3]?.type == 'FACEBOOK' ? FooterImages.facebook : webInfo?.web_info_links[3]?.type == 'YOUTUBE' ? FooterImages.youtube : FooterImages.tiktok}
                      // onClick={() =>
                      //   window
                      //     .open(
                      //       `${webInfo?.web_info_links[3]?.url}`,
                      //       "_blank"
                      //     )
                      //     ?.focus()
                      // }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12">
          <div className="col col-lg-4">
            <div className="txt-title-footer">{webInfo?.title}</div>
            <div className="txt-title-down">
              {webInfo?.name}
            </div>
            <div className="txt-content-footer">Slogan: {webInfo?.slogan}</div>
            <div className="txt-content-footer">
              Mã số doanh nghiệp: {webInfo?.code}
            </div>
            <div className="txt-content-footer">
              Trụ sở: {webInfo?.address}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="txt-title-footer">Truy cập nhanh:</div>
            <div className="d-flex gap-5">
              <div className="d-flex flex-column gap-1 mt-2">
                {Footer_Button.slice(0, 8).map((i, idx) => {
                  return (
                    <button
                      onClick={() => {
                        if (i.id === 7 || i.id === 8 || i.id === 9) {
                          handleNavigate(i.id);
                        } else {
                          navigate("/chi-tiet-du-hoc", {
                            state: { content: i.content },
                          });
                        }
                      }}
                      key={i.id}
                      className="btn-content-footer"
                    >
                      {i.text}
                    </button>
                  );
                })}
              </div>
              <div className="d-flex flex-column gap-1 mt-2">
                {Footer_Button.slice(8, 11).map((i) => {
                  return (
                    <button
                      onClick={() => {
                        if (i.id === 7 || i.id === 8 || i.id === 9) {
                          handleNavigate(i.id);
                        } else {
                          navigate("/chi-tiet-du-hoc", {
                            state: { content: i.content },
                          });
                        }
                      }}
                      key={i.id}
                      className="btn-content-footer"
                    >
                      {i.text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="container-contact-footer">
              <div className="txt-title-footer">Thông tin liên hệ:</div>
              <div className="d-flex align-items-center gap-2 mt-2">
                <PhoneOutlined style={{ fontSize: 20, color: "#027662" }} />
                <div>
                  {
                    webInfo && webInfo?.web_info_hotlines?.map((item: any) => {
                      return (
                        <div className="txt-hotline"key={item.id}>
                          Hotline {item.type == 'DU_HOC' ? 'Du Học' : 'Du Lịch'}: {item?.phone}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-1">
                <MailOutlined style={{ fontSize: 20, color: "#027662" }} />
                <div className="txt-email">Email: {webInfo?.email}</div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-1">
                <ChromeOutlined style={{ fontSize: 20, color: "#027662" }} />
                <div className="txt-email">Website: {webInfo?.website}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "linear-gradient(210deg,#007261, #21c586)",
          marginTop: 24,
        }}
      >
        <div className="container-xxl d-flex justify-content-between align-items-center py-4">
          <div className="text-white fw-medium">
            DABOSAGROUP@All rights reserved.
          </div>
          <button
            className="border border-0 p-0 btn text-white fw-medium"
            onClick={() => window.scrollTo(0, 0)}
          >
            Trở về đầu trang
            <CaretUpFilled style={{ marginLeft: 8 }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
