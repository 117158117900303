import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import "./App.css";
import StudyAbroad from "./pages/StudyAbroad";
import HomePage from "./pages/HomePage";
import { PhoneFilled } from "@ant-design/icons";
import WebFont from "webfontloader";
import IntroDabosa from "./pages/IntroDabosa";
import Leader from "./pages/Leader";
import Course from "./pages/Course";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Recruitment from "./pages/Recruitment";
import DetailStudyProgram from "./pages/DetailStudyProgram";
import DetailCamNang from "./pages/DetailCamNang";
import DetailTravel from "./pages/DetailTravel";
import NewDetail from "./pages/DetailNew";
import CacHoatDongTieuBieuDuHoc from "./pages/CacHoatDongTieuBieu/CacHoatDongTieuBieuDuHoc";
import CacHoatDongTieuBieuDuLich from "./pages/CacHoatDongTieuBieu/CacHoatDongTieuBieuDuLich";
import DetailRecruitMent from "./pages/DetailRecruitment";
import CamNangDuLich from "./pages/CamNangDuLich";
import ChiTietTinTuc from "./pages/ChiTietTinTuc";
import ChiTietCamNang from "./pages/ChiTietCamNang";
import ChiTietHoatDongTieuBieu from "./pages/ChiTietHoatDongTieuBieu";
import DetailBlog from "./pages/Blog";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["SVN-Gilroy"],
      },
    });
  }, []);

  return (
    <div className="container-homepage">
      <div className="position-relative">
        <div className="position-absolute">
          <a
            href="tel:+84961631818"
            className="align-items-center d-flex flex-column gap-2"
            style={{
              textDecoration: "none",
              color: "#00b09b",
              position: "fixed",
              right: 24,
              zIndex: 100,
              top: '30%',
            }}
          >
            <div className="container-img-contact">
              <PhoneFilled style={{ color: "red", fontSize: 18 }} />
            </div>
            <div className="txt-contact">Du học</div>
          </a>
        </div>
        <a
          href="tel:+84961351818"
          className="align-items-center d-flex flex-column gap-2"
          style={{
            textDecoration: "none",
            color: "#00b09b",
            position: "fixed",
            right: 24,
            zIndex: 100,
            top: '40%',
          }}
        >
          <div className="container-img-contact">
            <PhoneFilled style={{ color: "#00b09b", fontSize: 18 }} />
          </div>
          <div className="txt-contact">Du lịch</div>
        </a>
        <a
          href="https://chat.zalo.me/?phone=0961631818"
          className="align-items-center d-flex flex-column gap-2"
          style={{
            textDecoration: "none",
            color: "#00b09b",
            position: "fixed",
            right: 24,
            zIndex: 100,
            top: '51%',
          }}
        >
          <img
            src={require("src/assets/zalo.png")}
            width={32}
            height={32}
            alt=""
          />
          <div className="txt-contact">Du học</div>
        </a>
        <a
          href="https://chat.zalo.me/?phone=0961631818"
          className="align-items-center d-flex flex-column gap-2"
          style={{
            textDecoration: "none",
            color: "#00b09b",
            position: "fixed",
            right: 24,
            zIndex: 100,
            top: '61%',
          }}
        >
          <img
            src={require("src/assets/zalo.png")}
            width={32}
            height={32}
            alt=""
          />
          <div className="txt-contact">Du lịch</div>
        </a>
        <a
          href="https://m.me/110328294742120"
          className="align-items-center d-flex flex-column gap-2"
          style={{
            textDecoration: "none",
            color: "#00b09b",
            position: "fixed",
            right: 10,
            zIndex: 100,
            top: '71%',
          }}
        >
          <img
            src={require("src/assets/mess.png")}
            width={36}
            height={36}
            alt=""
          />
          <div className="txt-contact">Messenger</div>
        </a>
        <a
          href="https://www.youtube.com/@GROUPDABOSA"
          className="align-items-center d-flex flex-column gap-2"
          style={{
            textDecoration: "none",
            color: "#00b09b",
            position: "fixed",
            right: 18,
            zIndex: 100,
            top: '81%',
          }}
        >
          <img
            src={require("src/assets/youtube.png")}
            width={32}
            height={32}
            alt=""
          />
          <div className="txt-contact">Youtube</div>
        </a>
      </div>
      <Header />
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route index path="/du-hoc" element={<StudyAbroad />} />
        <Route index path="/du-lich" element={<Home />} />
        <Route
          index
          path="/introducing-dabosa-group"
          element={<IntroDabosa />}
        />
        <Route index path="/lanh-dao" element={<Leader />} />
        <Route index path="/course" element={<Course />} />
        <Route index path="/news" element={<News />} />
        <Route index path="/cac-hoat-dong-tieu-bieu/duhoc" element={<CacHoatDongTieuBieuDuHoc />} />
        <Route index path="/cac-hoat-dong-tieu-bieu/dulich" element={<CacHoatDongTieuBieuDuLich />} />
        <Route index path="/contact" element={<Contact />} />
        <Route index path="/recruitment" element={<Recruitment />} />
        <Route index path="/chi-tiet-du-hoc" element={<DetailStudyProgram />} />
        <Route index path="/cam-nang-chi-tiet" element={<DetailCamNang />} />
        <Route index path="/chi-tiet-du-lich" element={<DetailTravel />} />
        <Route index path="/chi-tiet-tin-tuc" element={<NewDetail />} />
        <Route index path="/detail-recruitment" element={<DetailRecruitMent />} />
        <Route index path="/cam-nang-du-lich" element={<CamNangDuLich />} />
        <Route index path="/chi-tiet-tin-tuc" element={<ChiTietTinTuc />} />
        <Route index path="/chi-tiet-cam-nang" element={<ChiTietCamNang />} />
        <Route index path="/chi-tiet-hoat-dong-tieu-bieu" element={<ChiTietHoatDongTieuBieu />} />
        <Route index path="/blog/:id" element={<DetailBlog />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
