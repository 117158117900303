const ReviewImages = {
    bg_review: require('./bg_review.png'),
    tym: require('./tym.png'),
    comment: require('./comment.png'),
    tele: require('./tele.png'),
    "1": require('./1.png'),
    "2": require('./2.png'),
    "3": require('./3.png'),
    "4": require('./4.png'),
}

export default ReviewImages