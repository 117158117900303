import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Study_DaiLoan,
  Study_Duc,
  Study_Han,
  Study_Nhat,
  Study_TrungQuoc,
  Study_UC,
} from "src/constant/Choore";

const DropdownDuHoc = () => {
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() =>
            navigate("/du-hoc", {
              state: { id: 'du-hoc', type: 'DU_HOC' },
            })
          }
          className="btn border border-0 p-0"
        >
          Du học Nhật Bản
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() =>
            navigate("/du-hoc", {
              state: { id: 'du-hoc', type: 'DU_HOC' },
            })
          }
          className="btn border border-0 p-0"
        >
          Du học Hàn Quốc
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() =>
            navigate("/du-hoc", {
              state: { id: 'du-hoc', type: 'DU_HOC' },
            })
          }
          className="btn border border-0 p-0"
        >
          Du học Đức
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() =>
            navigate("/du-hoc", {
              state: { id: 'du-hoc', type: 'DU_HOC' },
            })
          }
          className="btn border border-0 p-0"
        >
          Du học Úc
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() =>
            navigate("/du-hoc", {
              state: { id: 'du-hoc', type: 'DU_HOC' },
            })
          }
          className="btn border border-0 p-0"
        >
          Du học Trung Quốc
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() =>
            navigate("/du-hoc", {
              state: { id: 'du-hoc', type: 'DU_HOC' },
            })
          }
          className="btn border border-0 p-0"
        >
          Du học Đài Loan
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div
          className="btn border border-0 p-0"
          onClick={() => navigate("/du-hoc", {
            state: { id: 'cam-nang-du-hoc', type: 'CAM_NANG_DU_HOC' },
          })}
        >
          Cẩm nang du học
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <div
          onClick={() => navigate("/du-hoc", {
            state: { id: 'du-hoc-tieu-bieu', type: 'DU_HOC_TIEU_BIEU' },
          })}
          className="btn border border-0 p-0"
        >
          Các hoạt động tiêu biểu
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <button className="btn-right fw-medium gap-1 d-flex align-items-center">
        DU HỌC
        <DownOutlined style={{ fontSize: 10 }} />
      </button>
    </Dropdown>
  );
};

export default DropdownDuHoc;
