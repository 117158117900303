import { ArrowRightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomePageImages from "src/assets/HomePage";
import HeadTab from "src/components/HeadTab/HeadTab";
import { baseApi } from "src/constant";
import { Rec_List } from "src/constant/Array";

const Recruitment = () => {
  const navigate = useNavigate()

  const [news, setNews] = useState<any>()
  useEffect(() => {
    window.scrollTo(0, 0);

    fetch(`${baseApi}/blogs/?category__name=Tuyển dụng`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json(); // Parse JSON
    })
    .then((res: any) => {
      setNews(res)
    })
  },[])

  if(news)
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
            Các vị trí tuyển dụng
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>

        <div className="pt-5 row col-md-12">
          {news.map((item: any) => (
            <>
              <div className="d-block d-sm-none mt-3" />
              <div className="col-md-3">
                <div className="w-100">
                  <img src={item.thumbnail} className="w-100 rounded-2" alt="" />
                  <div className="py-2 text-success fw-semibold">
                    {item.title}
                  </div>
                  <button onClick={() => navigate(`/blog/${item.id}`)} className="btn-see-more-rec">
                    <ArrowRightOutlined />
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Recruitment;
